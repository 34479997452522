<template>
  <!-- Ref: https://www.bootdey.com/snippets/view/chat-app#html -->

  <div class="card-chat">
    <div class="chat">
      <div class="chat-header clearfix">
        <div class="row">
          <div class="col-lg-6">
            <!-- <a href="javascript:void(0);" data-toggle="modal" data-target="#view_info" >
              <img src="https://bootdey.com/img/Content/avatar/avatar2.png" alt="avatar" />
            </a> -->
            <div class="chat-about">
              <h3 class="mb-0">{{ ticketComments.subject }}</h3>
              <small>
                # {{ ticketComments.ticketId }}
                <i
                  class="fa fa-circle pl-1"
                  :class="getStatus(ticketComments.status, true)"
                ></i>
                {{ getStatus(ticketComments.status, false) }}
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="chat-history">
        <ul class="m-b-0">
          <li
            v-for="(comment, index) in ticketComments.comments"
            :key="index"
            class="clearfix"
          >
            <div
              class="message-data"
              :class="isMe(comment.author_id, 'message-data')"
            >
              <span class="message-data-time">{{
                getTimeStamp(comment.created_at)
              }}</span>
              <!-- <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="avatar"> </div> -->
            </div>
            <div
              class="message"
              :class="isMe(comment.author_id, 'message')"
              v-html="comment.html_body"
            ></div>
            <div v-if="comment.attachments.length">
              <div class="clearfix"></div>
              <div
                v-for="(att, i) in comment.attachments"
                :key="i"
                class="mt-2"
                :class="isMe(comment.author_id, 'file')"
              >
                <img
                  :src="att.thumbnail_url"
                  v-if="att.thumbnail_url"
                  @click="onFileClicked(att.content_url)"
                />
                <a v-else :href="att.content_url">{{ att.name }}</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div
        class="chat-message clearfix"
        v-if="getStatus(ticketComments.status, false) === 'open'"
      >
        <div class="input-group mb-0">
          <div class="input-group-prepend">
            <span
              class="input-group-text"
              style="height: 46px; width: 4rem"
              v-bind:style="[
                typeof selectedImage === 'undefined' ||
                typeof selectedImage === 'string'
                  ? { width: '3rem' }
                  : { width: '5rem' },
              ]"
            >
              <!-- <input
                type="file"
                @change="handleFileSelect"
                class="fa fa-paperclip"
              /> -->
              <v-file-input
                v-model="selectedImage"
                :rules="rules"
                truncate-length="1"
              ></v-file-input>
            </span>
          </div>
          <input
            type="text"
            class="form-control pl-1"
            placeholder="Enter text here..."
            v-model="message"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <i
                class="fa fa-send"
                v-on:click="onSubmitClicked()"
                style="cursor: pointer"
              ></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import "@/assets/css/support_chat.css";
import * as SupportClient from "@/client/SupportClient";

export default {
  name: "SupportChat",
  props: {
    ticketComments: Object,
    onSubmit: Function,
  },

  data() {
    return {
      message: "",
      myEmail: this.$store.state.account.email,
      selectedImage: "",
      isLoading: false,
      isDisable: false,
      rules: [
        (value) => {
          if (value && value.size >= 5000000) {
            this.isDisable = true;
            this.showConnectionErrorDialog(
              "File size should be less than 5 MB!"
            );
            return "File size should be less than 5 MB!";
          } else {
            this.isDisable = false;
            return true;
          }
        },
      ],
    };
  },

  methods: {
    isMe(id, classType) {
      switch (classType) {
        case "message":
          return id == this.ticketComments.myId
            ? "my-message float-right"
            : "other-message";
        case "message-data":
          return id == this.ticketComments.myId ? "text-right" : "";
        case "file":
          return id == this.ticketComments.myId ? "float-right" : "";
      }
    },

    getTimeStamp(timestamp) {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return new Date(timestamp).toLocaleString("en-IN", {
        timeZone: tz,
      });
    },

    async onSubmitClicked() {
      if (this.isDisable) return;
      try {
        this.isLoading = true;
        if (!this.message.trim()) {
          throw "The message can't be empty!";
        }
        let res = await SupportClient.ReplyToTicket(
          this.myEmail,
          this.ticketComments.ticketId,
          this.message.trim(),
          this.selectedImage
        );
        // Refresh the chat
        await this.onSubmit(this.ticketComments.ticketId);
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
        this.message = "";
        this.selectedImage = "";
      }
    },

    onFileClicked(url) {
      window.open(url, "_blank");
    },

    getStatus(status, isClass) {
      switch (status) {
        case "new":
        case "open":
        case "pending":
        case "hold":
          return isClass ? "online" : "open";
        case "solved":
        case "closed":
          return isClass ? "offline" : "closed";
      }
    },

    showConnectionErrorDialog(error) {
      this.$swal.fire({
        icon: "error",
        text: error,
      });
    },
  },
};
</script>