<template>
  <v-card tile>
    <v-subheader>Existing Tickets</v-subheader>
    <div class="row align-items-center">
      <div class="col">
        <v-list nav style="max-height: 552px; overflow-y: scroll">
          <v-list-item-group active-class="green--text">
            <v-list-item @click="onSelect(0)" class="blue--text" active>
              <v-icon> mdi-plus </v-icon>
              <v-list-item-title> Create New Ticket </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-for="(ticket, index) in tickets"
              :key="index"
              @click="onSelect(ticket.id)"
            >
              <!-- <v-list-item-avatar>
                  <v-icon class="grey subtitle-1" dark> {{ ticket.status }} </v-icon>
                </v-list-item-avatar> -->
              <v-list-item-content>
                <v-list-item-subtitle>
                  # {{ ticket.id }}
                  <i
                    class="fa fa-circle pl-1"
                    :class="getStatus(ticket.status, true)"
                  ></i>
                  {{ getStatus(ticket.status, false) }}
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ ticket.subject }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>
                  {{
                    new Date(ticket.updated_at) | moment("from", "now", true)
                  }}
                </v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "SupportTicketList",
  props: {
    tickets: Array,
    onSelect: Function,
  },
  data() {
    return {
      email: this.$store.state.account.email,
    };
  },
  methods: {
    getStatus(status, isClass) {
      switch (status) {
        case "new":
        case "open":
        case "pending":
        case "hold":
          return isClass ? "online" : "open";
        case "solved":
        case "closed":
          return isClass ? "offline" : "closed";
      }
    },
  },
};
</script>