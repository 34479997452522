/**
 * Sage Zendesk Supoort Client
 */

import axios from "axios"
import apiConfig from '../config/api.config';

// access_token: localStorage.getItem("access_token")

export function GetUserTickets(email) {
    return new Promise((resolve, reject) => {
        let payload = {
            emailId: email,
            access_token: localStorage.getItem("access_token")
        }
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.SUPPORT_GET_TICKETS, payload)
            .then(response => {
                resolve(response.data.result);
            }).catch(error => {
                reject(error);
            })
    })
}

export function GetTicketComments(email, ticketId) {
    return new Promise((resolve, reject) => {
        let payload = {
            emailId: email,
            access_token: localStorage.getItem("access_token"),
            ticketId: ticketId
        }
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.SUPPORT_GET_COMMENTS, payload)
            .then(response => {
                resolve(response.data.result);
            }).catch(error => {
                reject(error);
            })
    })
}

export function CreateTickets(email, subject, comment, attachment) {
    return new Promise((resolve, reject) => {
        let payload = new FormData();
        let header = {}

        payload.append("emailId", email);
        payload.append("access_token", localStorage.getItem("access_token"));
        payload.append("subject", subject);
        payload.append("comment", comment);
        if (attachment) {
            console.log("file");
            payload.append("attachment", attachment);
            header = { 'Content-Type': 'multipart/form-data' }
        }
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.SUPPORT_CREATE_TICKET, payload, header)
            .then(response => {
                resolve(response.data.result);
            }).catch(error => {
                reject(error);
            })
    })
}

export function ReplyToTicket(email, ticketId, comment, attachment) {
    return new Promise((resolve, reject) => {
        let payload = new FormData()
        let header = {}

        payload.append("emailId", email);
        payload.append("access_token", localStorage.getItem("access_token"));
        payload.append("ticketId", ticketId);
        payload.append("comment", comment);
        if (attachment) {
            console.log("file");
            payload.append("attachment", attachment);
            header = { 'Content-Type': 'multipart/form-data' }
        }
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.SUPPORT_REPLY_TICKETS, payload, header)
            .then(response => {
                resolve(response.data.result);
            }).catch(error => {
                reject(error);
            })
    })
}