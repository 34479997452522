<template>
  <v-card outlined elevation="1" class="p-3">
    <v-card-title style="padding-left: 0px">Create New Ticket</v-card-title>
    <div class="row">
      <div class="input-group col-lg-12 mb-4">
        <input
          id="subject"
          type="text"
          name="subject"
          placeholder="Subject"
          class="form-control bg-white border-md"
          v-model="subject"
        />
      </div>
      <div class="input-group col-lg-12 mb-4">
        <textarea
          class="form-control bg-white border-md"
          placeholder="Description"
          v-model="message"
        >
        </textarea>
      </div>
      <div class="input-group col-lg-12 mb-4">
        <v-file-input v-model="selectedImage" :rules="rules"></v-file-input>
      </div>
      <button
        class="swal2-confirm swal2-styled mx-auto"
        type="button"
        @click="onCreateTicket()"
        v-bind:disabled="isDisable"
      >
        Create Ticket
      </button>
    </div>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import * as SupportClient from "@/client/SupportClient";

export default {
  name: "SupportCreateTicket",
  props: {
    getTicketList: Function,
    onTicketSelect: Function,
  },

  data() {
    return {
      subject: "",
      message: "",
      selectedImage: [],
      isLoading: false,
      isDisable: false,
      rules: [
        (value) => {
          if (value && value.size >= 1000000) {
            this.isDisable = true;
            return "File size should be less than 1 MB!";
          } else {
            this.isDisable = false;
            return true;
          }
        },
      ],
    };
  },

  methods: {
    async onCreateTicket() {
      let ticketCreated = false;
      try {
        this.isLoading = true;
        if (!this.subject.trim()) {
          throw "The subject can't be empty!";
        }
        if (!this.message.trim()) {
          throw "The description can't be empty!";
        }
        let res = await SupportClient.CreateTickets(
          this.$store.state.account.email,
          this.subject.trim(),
          this.message.trim(),
          this.selectedImage
        );
        ticketCreated = true;
        await this.getTicketList();
        await this.onTicketSelect(res.ticketId);
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
        if (ticketCreated) {
          this.subject = "";
          this.message = "";
          this.selectedImage = "";
        }
      }
    },

    showConnectionErrorDialog(error) {
      this.$swal.fire({
        icon: "error",
        text: error,
      });
    },
  },
};
</script>