<template>
  <div>
    <div class="main-content">
      <!-- Header -->
      <div class="header" style="padding-bottom: 8rem !important">
        <ResellerHeader :walletCredits="credits" />
      </div>
      <!-- Page content -->
      <div class="container-fluid mt--7">
        <div class="row">
          <!-- Right Side View -->
          <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
            <div class="card card-profile shadow">
              <div
                class="row justify-content-center"
                style="padding-bottom: 6vh"
              >
                <div class="col-lg-3 order-lg-2">
                  <div class="card-profile-image">
                    <a href="#">
                      <img
                        :src="this.$store.state.account.profilePic"
                        class="rounded-circle"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="
                  card-header
                  text-center
                  border-0
                  pt-8 pt-md-4
                  pb-0 pb-md-4
                "
              >
                <div class="d-flex justify-content-between"></div>
              </div>
              <div class="card-body pt-0 pt-md-4">
                <div class="row">
                  <div class="col">
                    <div
                      class="card-profile-stats d-flex justify-content-center"
                      style="padding: 0"
                    >
                      <div>
                        <span class="heading">{{ walletCredits }}</span>
                        <span class="description">MF Credits</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <!-- MF Credits Input -->
                  <!-- <div class="input-group col-lg-12 mb-4">
                    <input
                      id="pack-input"
                      class="form-control bg-white border-md"
                      type="number"
                      :placeholder="amountPlaceholder"
                      v-model="packAmount"
                      @input="convertToInteger"
                    />
                  </div>
                  <p v-if="!isNaN(parseFloat(packAmount))">
                    Amount payable: {{ currency }}
                    <strong style="font-weight: 900"
                      >{{ getAmountInclusiveTaxes(this.packAmount) }}/-</strong
                    >
                    ({{ getAmountExcludingTaxes(parseFloat(packAmount)) }} +
                    {{ taxRate }}% taxes)
                  </p> -->

                  <!-- <ActionButton
                    text="Buy Now"
                    @onclick="submit()"
                    class="mb-4"
                    style="flex: 0 0 40%; max-width: 50%"
                  /> -->

                  <!-- <hr class="my-4" />
                  <p>
                    Range of MF Credits you can purchase:
                    <strong style="font-weight: 900">{{
                      minAmountWithCountryCode[resellerCountryCode]
                    }}</strong>
                    to
                    <strong style="font-weight: 900">{{
                      maxAmountWithCountryCode[resellerCountryCode]
                    }}</strong>
                    MF Credits.
                  </p> -->
                  <!-- <p>
                    By clicking Buy Now, you are agreeing with
                    <router-link :to="{ name: 'paymentTnC' }" target="_blank"
                      >Terms &amp; Conditions</router-link
                    >
                    of Moonfrog
                  </p> -->
                </div>
              </div>
            </div>
          </div>
          <!-- Left Side View -->
          <div class="col-xl-8 order-xl-1" style="padding-bottom: 2rem">
            <div class="card bg-secondary shadow">
              <div class="card-header bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">My Account</h3>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <form>
                  <h6 class="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div class="pl-lg-4">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group focused">
                          <label class="form-control-label" for="input-username"
                            >Name</label
                          >
                          <input
                            type="text"
                            id="input-username"
                            class="form-control form-control-alternative"
                            v-bind:placeholder="resellerName"
                            readonly
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-control-label" for="input-email"
                            >Email address</label
                          >
                          <input
                            type="email"
                            id="input-email"
                            class="form-control form-control-alternative"
                            v-bind:placeholder="$store.state.account.email"
                            readonly
                          />
                        </div>
                      </div>
                      <div
                        class="col-lg-6"
                        v-if="$store.state.account.userType === 'SUBRESELLER'"
                      >
                        <div class="form-group">
                          <label class="form-control-label" for="input-email"
                            >Parent Distributor</label
                          >
                          <input
                            type="email"
                            id="input-email"
                            class="form-control form-control-alternative"
                            v-bind:placeholder="parentReseller"
                            readonly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="my-4" />

                  <h6 class="heading-small text-muted mb-4">
                    Purchase History
                  </h6>
                  <div class="row mb-5">
                    <div
                      class="col-lg-8 mx-auto"
                      style="max-width: none; flex: none"
                    >
                      <div class="bg-white rounded shadow">
                        <v-card>
                          <v-card-title>
                            <v-spacer></v-spacer>
                          </v-card-title>
                          <v-data-table
                            :headers="headers"
                            :items="transactionsArr"
                            disable-pagination
                            :hide-default-footer="true"
                            class="elevation-1"
                          >
                            <!-- eslint-disable -->
                            <template v-slot:item.timestamp="{ item }">
                              {{ getTimeStamp(item.timestamp) }}
                            </template>
                            <template v-slot:item.status="{ item }">
                              <v-chip
                                :color="getPaymentColor(item.status)"
                                dark
                              >
                                {{ getStatusCode(item.status) }}
                              </v-chip>
                            </template>
                          </v-data-table>
                          <v-pagination
                            v-model="page"
                            :length="totalPages"
                            total-visible="7"
                            next-icon="mdi-menu-right"
                            prev-icon="mdi-menu-left"
                            @input="handlePageChange"
                          ></v-pagination>
                        </v-card>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Support -->
      <div class="container-fluid" full-height>
        <h3 style="color: white; text-transform: uppercase">Help Center</h3>
      </div>
      <div class="container-fluid mt-auto">
        <div class="row">
          <!-- Right side view -->
          <div class="col-xl-4 order-xl-2 mx-auto" style="padding-bottom: 2rem">
            <div class="card card-profile shadow">
              <SupportTicketList
                :tickets="tickets"
                :onSelect="onTicketSelect"
              />
            </div>
          </div>
          <!-- Left side view -->
          <div
            class="col-xl-8 order-xl-1 mx-auto"
            style="padding-bottom: 2rem"
            id="support-left-side"
          >
            <SupportChat
              :onSubmit="onTicketSelect"
              :ticketComments="ticketComments"
              v-if="selectedTicketId"
            />
            <SupportCreateTicket
              v-else
              :getTicketList="getTicketList"
              :onTicketSelect="onTicketSelect"
            />
          </div>
        </div>
      </div>
    </div>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import "@/assets/css/reseller.css";
import ActionButton from "../components/ActionButton";
import ResellerHeader from "../components/ResellerHeader";
import SupportCreateTicket from "@/components/Support/SupportCreateTicket";
import SupportTicketList from "@/components/Support/SupportTicketList";
import SupportChat from "@/components/Support/SupportChat";
import * as SupportClient from "@/client/SupportClient";
import * as PaymentClient from "@/client/PaymentClient";
import * as client from "@/client/webclient";
import * as SageConstants from "../config/SageConstants";
import * as SageUtils from "@/utils/SageUtils";

// My Account Page
export default {
  name: "Reseller_RZP",
  components: {
    // ActionButton,
    ResellerHeader,
    SupportCreateTicket,
    SupportTicketList,
    SupportChat,
  },
  props: {
    resellerId: String,
  },
  data() {
    return {
      resellerName: "Reseller",
      resellerPhone: "",
      resellerCountryCode: "IN",
      credits: 0,
      parentReseller: "",

      transactionsArr: [],
      transactionsLoaded: false,

      headers: [
        { text: "Time", align: "start", value: "timestamp" },
        { text: "MF Credits", value: "mfvalue" },
        { text: "Price", value: "price" },
        { text: "Status", value: "status" },
        { text: "Type", value: "txnType" },
      ],
      isLoading: true,

      page: 1,
      totalPages: 0,

      packAmount: Number,

      resellerData: {},

      minAmountWithCountryCode: {
        IN: SageConstants.minCreditCountry.IN,
        BD: SageConstants.minCreditCountry.BD,
      },
      maxAmountWithCountryCode: {
        IN: SageConstants.maxCreditCountry.IN,
        BD: SageConstants.maxCreditCountry.BD,
      },
      creditExchangeRate: Number,

      tickets: [],
      selectedTicketId: "",
      ticketComments: {},
    };
  },
  created() {
    let rzpScript = document.createElement("script");
    rzpScript.setAttribute(
      "src",
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    document.head.appendChild(rzpScript);
    this.init();
  },
  computed: {
    walletCredits: function () {
      if (!this.credits) {
        return 0;
      }
      return this.credits.toFixed(2);
    },
    amountPlaceholder: function () {
      return (
        "Enter Required MF Credits (Minimum " +
        this.minAmountWithCountryCode[this.resellerCountryCode] +
        ")"
      );
    },
    currency: function () {
      return SageUtils.getCurrency(this.resellerCountryCode);
    },
    taxRate: function () {
      return SageUtils.getTaxRate(this.resellerData.countryCode);
    },
  },
  methods: {
    async init() {
      // if (this.$route.query.spTransID) {
      //   this.checkBKashTransactionStatus(this.$route.query.spTransID);
      // }
      if (this.$route.query.success) {
        this.showAamarpayStatus(this.$route.query);
      }
      try {
        const key = await PaymentClient.getRazorpayKey();
        this.$store.commit("setRazorpayKey", key);
        this.resellerData = await client.getReseller(
          this.$store.state.account.email,
          this.$store.state.account.email
        );
        this.resellerName = this.resellerData.name;
        this.resellerPhone = this.resellerData.user.phoneNumber;
        this.resellerCountryCode = this.resellerData.countryCode
          ? this.resellerData.countryCode
          : "IN";
        this.parentReseller = this.resellerData.parentResellerId;

        let countryCode = this.resellerCountryCode;
        if (!this.$store.state.creditExchangeRate[countryCode]) {
          PaymentClient.getCreditExchangeRate().then((creditExchangeRate) => {
            this.$store.commit("setCreditExchangeRate", creditExchangeRate);
          });
        }
        this.creditExchangeRate =
          this.$store.state.creditExchangeRate[countryCode];

        let txnData = await client.userAllPagedTransactions(
          this.page,
          this.$store.state.account.email
        );
        this.transactionsArr = txnData.objArr;
        this.totalPages = txnData.totalPages;
        this.transactionsLoaded = true;

        const creditData = await client.getMoonfrogCredits(
          this.$store.state.account.email,
          this.$store.state.account.email
        );
        this.credits = creditData;

        await this.getTicketList();
      } catch (e) {
        this.showConnectionErrorDialog(e);
      } finally {
        this.isLoading = false;
      }
    },

    // async submit() {
    //   switch (this.resellerCountryCode) {
    //     case SageConstants.countryCode.IN:
    //       await this.showRazorpayPopUp();
    //       return;
    //     // case SageConstants.countryCode.BD:
    //     //   await this.showBKashPopUp();
    //     //   return;
    //     case SageConstants.countryCode.BD:
    //       await this.showAamarpayPopUp();
    //       return;
    //     default:
    //       this.showConnectionErrorDialog(
    //         "Payment method not available for your country."
    //       );
    //   }
    // },

    loadRazorpay() {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    },

    async showRazorpayPopUp() {
      const packAmount = parseFloat(this.packAmount);
      let err = false;

      packAmount < this.minAmountWithCountryCode[this.resellerCountryCode] ||
      packAmount > this.maxAmountWithCountryCode[this.resellerCountryCode] ||
      isNaN(packAmount)
        ? (this.showWarningBorder("pack-input"), (err = true))
        : this.showNormalBorder("pack-input");

      try {
        if (!err) {
          this.isLoading = true;
          if (!this.$store.state.razorpayKey) {
            const key = await PaymentClient.getRazorpayKey();
            this.$store.commit("setRazorpayKey", key);
          }
          const res = await this.loadRazorpay();

          if (!res) {
            this.showConnectionErrorDialog(
              "Razorpay SDK failed to load. Check your internet connection."
            );
            return;
          }
          const order = await PaymentClient.createRazorpayOrder(
            this.getAmountInclusiveTaxes(packAmount),
            this.$store.state.account.email
          );

          const that = this;

          const payOptions = {
            key: this.$store.state.razorpayKey,
            amount: order.orderAmount,
            currency: order.currency,
            name: "Moonfrog Labs Pvt Ltd",
            description: packAmount + " MF Credits Purchase",
            order_id: order.clientData.id,
            // if instead of handler, you want to use callback below is an example.
            //callback_url: "http://localhost:8080/razorpay", //write the reseller dashboard link here
            handler: async function (response) {
              that.$swal.fire({
                icon: "success",
                title: "Payment is Successful",
                text: "The page will reload in 10 seconds.",
                showConfirmButton: false,
              });
              that.isLoading = true;
              setTimeout(() => {
                that.$router.go();
              }, 10000);
            },
            prefill: {
              name: this.resellerName,
              email: this.$store.state.account.email,
              contact: this.resellerPhone,
            },
            theme: {
              color: "#80C767",
            },
            notes: {
              email: this.$store.state.account.email,
              isSage: "true",
              packAmount: this.packAmount,
              gst: this.taxRate,
              txnType : SageConstants.TransactionType.MFCredit
            },
          };

          this.isLoading = false;
          const rzp = new window.Razorpay(payOptions);
          rzp.open();
        }
      } catch (e) {
        this.isLoading = false;
        this.showConnectionErrorDialog(e);
      }
    },

    // async showBKashPopUp() {
    //   const packAmount = parseFloat(this.packAmount);
    //   let err = false;

    //   packAmount < this.minAmountWithCountryCode[this.resellerCountryCode] ||
    //   packAmount > this.maxAmountWithCountryCode[this.resellerCountryCode] ||
    //   isNaN(packAmount)
    //     ? (this.showWarningBorder("pack-input"), (err = true))
    //     : this.showNormalBorder("pack-input");

    //   if (err) return;
    //   try {
    //     this.isLoading = true;
    //     const amount = SageUtils.getAmountInclusiveTaxes(
    //       packAmount,
    //       this.resellerCountryCode,
    //       this.creditExchangeRate
    //     );
    //     let paymentURL = await PaymentClient.createBKashOrder(
    //       this.$store.state.account.email,
    //       amount
    //     );
    //     if (paymentURL) window.open(paymentURL, "_self");
    //     else
    //       this.showConnectionErrorDialog(
    //         "Could not redirect to the payment page. Please try again"
    //       );
    //   } catch (err) {
    //     this.showConnectionErrorDialog(err);
    //   } finally {
    //     this.isLoading = false;
    //   }
    // },

    async showAamarpayPopUp() {
      const packAmount = parseFloat(this.packAmount);
      let err = false;

      packAmount < this.minAmountWithCountryCode[this.resellerCountryCode] ||
      packAmount > this.maxAmountWithCountryCode[this.resellerCountryCode] ||
      isNaN(packAmount)
        ? (this.showWarningBorder("pack-input"), (err = true))
        : this.showNormalBorder("pack-input");

      if (err) return;
      try {
        this.isLoading = true;
        const amount = SageUtils.getAmountInclusiveTaxes(
          packAmount,
          this.resellerCountryCode,
          this.creditExchangeRate
        );
        let paymentURL = await PaymentClient.createAamarpayOrder(
          this.$store.state.account.email,
          amount,
          packAmount
        );
        if (paymentURL) window.open(paymentURL, "_self");
        else
          this.showConnectionErrorDialog(
            "Could not redirect to the payment page. Please try again"
          );
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async showAamarpayStatus(query) {
      if (query.success === "true") {
        this.$swal
          .fire({
            icon: "success",
            title: "Payment is Successful",
            showConfirmButton: true,
          })
          .then(() => {
            this.$router.push("/account");
          });
      } else {
        this.$swal
          .fire({
            icon: "error",
            title: "Payment failed!",
            text: query.message,
            showConfirmButton: true,
          })
          .then(() => {
            this.$router.push("/account");
          });
      }
    },

    convertToInteger(e) {
      this.packAmount = parseInt(e.target.value);
    },

    showWarningBorder(id) {
      if (document.getElementById(id) !== null) {
        document.getElementById(id).classList.remove("border-md");
        document.getElementById(id).classList.add("border-danger");
      }
    },
    showNormalBorder(id) {
      if (document.getElementById(id) !== null) {
        document.getElementById(id).classList.remove("border-danger");
        document.getElementById(id).classList.add("border-md");
      }
    },

    async handlePageChange(value) {
      this.isLoading = true;
      this.transactionsLoaded = false;
      this.page = value;

      try {
        let txnData = await client.userAllPagedTransactions(
          this.page,
          this.$store.state.account.email
        );

        this.transactionsArr = txnData.objArr;
        this.totalPages = txnData.totalPages;
        this.transactionsLoaded = true;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    // async checkBKashTransactionStatus(spTransID) {
    //   try {
    //     let bKashPaymentResult = await PaymentClient.getBKashPaymentStatus(
    //       this.$store.state.account.email,
    //       spTransID
    //     );
    //     if (bKashPaymentResult.status === "CHARGED") {
    //       this.$swal.fire({
    //         icon: "success",
    //         title: "Payment is Successful",
    //         showConfirmButton: true,
    //       });
    //     } else {
    //       this.$swal.fire({
    //         icon: "error",
    //         title: "Payment failed!",
    //         showConfirmButton: true,
    //       });
    //     }
    //     await this.sleep(3000);
    //   } catch (err) {
    //     this.showConnectionErrorDialog(
    //       "Could not check payment status. Please contact support if money got deducted and credits not updated."
    //     );
    //   } finally {
    //     this.$router.push("/account");
    //   }
    // },

    getPaymentColor(status) {
      if (
        status === SageConstants.razorpayConstants.AUTHORIZED ||
        status === SageConstants.paymentConstants.PENDING
      )
        return "orange";
      else if (
        status === SageConstants.razorpayConstants.CAPTURED ||
        status === SageConstants.paymentConstants.APPROVED ||
        status === SageConstants.paymentConstants.SUCCESS
      )
        return "green";
      else return "red";
    },
    getStatusCode(status) {
      if (status === SageConstants.razorpayConstants.CAPTURED) return "SUCCESS";
      else return status;
    },
    getTimeStamp(timestamp) {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return new Date(timestamp).toLocaleString("en-IN", {
        timeZone: tz,
      });
    },

    showConnectionErrorDialog(error) {
      this.$swal.fire({
        icon: "error",
        text: error,
      });
    },

    getAmountInclusiveTaxes(credits) {
      return SageUtils.getAmountInclusiveTaxes(
        credits,
        this.resellerCountryCode,
        this.creditExchangeRate
      );
    },
    getAmountExcludingTaxes(credits) {
      return (credits / this.creditExchangeRate).toFixed(2);
    },

    async getTicketList() {
      this.tickets = await SupportClient.GetUserTickets(
        this.$store.state.account.email
      );
    },

    async onTicketSelect(ticketId) {
      try {
        this.isLoading = true;
        if (ticketId) {
          this.ticketComments = await SupportClient.GetTicketComments(
            this.$store.state.account.email,
            ticketId
          );
        }
        this.selectedTicketId = ticketId;
        document
          .getElementById("support-left-side")
          .scrollIntoView({ behavior: "smooth" });
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async onSubmitTicket(subject, message, file) {
      try {
        this.isLoading = true;
        let res = await SupportClient.CreateTickets(
          this.$store.state.account.email,
          subject,
          message,
          file
        );
        await this.getTicketList();
        await this.onTicketSelect(res.ticketId);
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async sleep(millSeconds) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, millSeconds);
      });
    },
  },
};
</script>